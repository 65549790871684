import React, { createContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { AppState, useSelector } from 'src/store/Store';
import { ColumnDef, ListTopCardsType } from 'src/store/types';
import * as routes from 'src/constants/routes';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Alert,
  Button,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Zoom,
} from '@mui/material';
import PageContainer from 'src/components/container/PageContainer';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import BlankCard from 'src/components/shared/BlankCard';
import CloseIcon from '@mui/icons-material/Close';
import { LazyListTableTemplate, ListTableTemplate } from '../list';
import { reset } from 'src/store/apps/setup/SetupSlice';

interface DynamicCrudTemplateProps {
  initialValues: {};
  pageTitle: string;
  pageHeaderTitle: string;
  pageHeaderDescription: string;
  yupSchema: any;
  submitAction: (values: any) => Promise<void>;
  submitState: { isSuccess: boolean; isError: boolean; error: any };
  children: React.ReactNode;
  crumb?: any[];
  listAllApi?: any;
  lazyParams?: {};
  listcolumns: ColumnDef[];
  listcards?: ListTopCardsType[];
  navigateTo?: string; // Navigate to prop
  formikValueChanged?: (formik: any, fieldName: string, fieldObject: any) => void;
  newButtonTitle: string;
  topButtonAction?: () => void;
  showEditAction: boolean;
  showDeleteAction?: boolean;
  gridSearchField?: string;
}

export const DynamicCrudTemplateContext = createContext({});

const DynamicCrudTemplate = (props: DynamicCrudTemplateProps) => {
  const customizer = useSelector((state: AppState) => state.customizer);
  const navigate = useNavigate(); // Hook for programmatic navigation
  const dispatch = useDispatch();
  const { ep, id } = useParams(); // `id` from the URL will determine if it's create or edit mode

  const [open, setOpen] = useState<boolean>(true);

  const selectedList = useSelector((state) => state.listReducer.currentList as any);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const isEditMode = id !== 'new'; // If `id` is not 'new', it's edit mode

  let BCrumb = [
    {
      to: routes.INDEX,
      title: 'Home',
    },
  ];

  if (props.crumb) {
    BCrumb = BCrumb.concat(props.crumb);
  } else if (customizer.breadCrumbs) {
    BCrumb = BCrumb.concat(customizer.breadCrumbs);
  }
  // To track whether a snackbar is currently showing
  const snackbarRef = useRef<boolean>(false);

  const formik = useFormik({
    initialValues: props.initialValues,
    validationSchema: props.yupSchema,
    enableReinitialize: true, // Ensures form values are reinitialized when `initialValues` change
    onSubmit: async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
      if (formik.isValid) {
        // Check if the form is valid before calling submitAction
        await props.submitAction(values);
        resetForm();
        navigate(props.navigateTo || '#');
      }
    },
  });

  // Consolidate snackbar notification logic
  const showSnackbar = (message: string, variant: 'success' | 'error', duration = 3000) => {
    // Prevent multiple snackbars being shown simultaneously
    if (snackbarRef.current) {
      return; // Do nothing if a snackbar is already showing
    }
    // Set snackbar showing flag
    snackbarRef.current = true;

    // Close any existing snackbar first
    closeSnackbar();

    enqueueSnackbar(message, {
      variant,
      autoHideDuration: duration,
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
      onClose: () => {
        // Reset the snackbar flag when the snackbar is closed
        snackbarRef.current = false;
      },
    });
  };

  useEffect(() => {
    if (props.submitState?.isSuccess && formik.isSubmitting) {
      formik.resetForm();
      formik.setStatus({ success: true });
      formik.setSubmitting(false);

      showSnackbar(
        `${props.pageHeaderTitle.replace('Setup', '')} has been saved successfully.`,
        'success',
      );

      navigate(props.navigateTo || '#');
    }

    if (props.submitState?.isError && formik.isSubmitting) {
      formik.setStatus({ success: false });
      formik.setErrors({ submit: props.submitState.error?.message });
      formik.setSubmitting(false);

      showSnackbar('Error occurred, please contact the administrator.', 'error');
    }
  }, [props.submitState, navigate, props.navigateTo, enqueueSnackbar, formik]);

  useEffect(() => {
    if (selectedList) {
      formik.resetForm();
      Object.keys(formik.initialValues).forEach((field) => {
        if (selectedList[field]) {
          formik.setFieldValue(field, selectedList[field], false);
        }
        if (props.formikValueChanged) {
          props.formikValueChanged(formik, field, selectedList);
        }
      });
      setOpen(false);
    }
  }, [selectedList]);

  const transformListApi = () => {
    const { generals, refetchGeneral, generalsIsFetching } = props.listAllApi || {};

    return {
      data: generals || [],
      error: null, // Assuming no specific error is passed in your `listAllApi`
      isFetching: generalsIsFetching || false,
      isSuccess: !generalsIsFetching && generals !== undefined,
      refetch: refetchGeneral,
    };
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {id === 'new' || Number(id) > 0 ? (
        <PageContainer title={props.pageTitle} description="This is the Setup page">
          <Breadcrumb
            title={props.pageHeaderTitle}
            subtitle={props.pageHeaderDescription}
            items={BCrumb}
          />
          <BlankCard gradients={['#FCFFE7', '#FAF8F9']}>
            <CardContent>
              <Grid container spacing={3}>
                <DynamicCrudTemplateContext.Provider value={formik}>
                  {props.children}

                  <Grid item xs={12}>
                    <Stack direction="column" spacing={1}>
                      {Object.keys(formik.errors)
                        .slice(0, 1)
                        .map(
                          (k: string, i) =>
                            (formik.errors as any)[k]?.length > 0 &&
                            open && (
                              <Alert
                                key={i.toString()}
                                severity="warning"
                                variant="outlined"
                                action={
                                  <IconButton onClick={() => setOpen(false)}>
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                {(formik.errors as any)[k]}
                              </Alert>
                            ),
                        )}
                    </Stack>
                  </Grid>

                  <Grid container justifyContent="flex-end">
                    <Grid item sx={{ mr: 3 }}>
                      <Stack direction="row" spacing={1}>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            formik.resetForm();
                            dispatch(reset());
                            navigate(props.navigateTo || '#');
                          }}
                          color="error"
                          size="large"
                        >
                          Cancel
                        </Button>

                        <Button
                          type="submit"
                          startIcon={formik.isSubmitting ? <CircularProgress size="1rem" /> : null}
                          disabled={formik.isSubmitting}
                          variant="contained"
                          disableElevation
                          color="primary"
                          size="large"
                          onClick={() => setOpen(true)}
                        >
                          {id === 'new' ? 'Save' : 'Update'}
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </DynamicCrudTemplateContext.Provider>
              </Grid>
            </CardContent>
          </BlankCard>
        </PageContainer>
      ) : Number(ep || 0) > 0 ? (
        <LazyListTableTemplate
          listTitle={`${props.pageHeaderTitle} List`}
          listSubTitle={props.pageHeaderDescription}
          columns={props.listcolumns}
          fetchLists={transformListApi}
          lazyParams={props.lazyParams}
          topCards={props.listcards}
          navigateTo={props.navigateTo}
          topButtonAction={props.topButtonAction}
          topButtonTitle={props.newButtonTitle}
          showDeleteAction={props.showDeleteAction}
          showEditAction={props.showEditAction}
          listName="main"
          searchField={props.gridSearchField}
        />
      ) : (
        <ListTableTemplate
          listTitle={`${props.pageHeaderTitle} List`}
          listSubTitle={props.pageHeaderDescription}
          columns={props.listcolumns}
          fetchLists={transformListApi}
          topCards={props.listcards}
          navigateTo={props.navigateTo}
          topButtonAction={props.topButtonAction}
          topButtonTitle={props.newButtonTitle}
          showEditAction={props.showEditAction}
          showDeleteAction={props.showDeleteAction}
          listName="main"
          searchField={props.gridSearchField}
        />
      )}
    </form>
  );
};

export default DynamicCrudTemplate;
