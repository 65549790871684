import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ControlCountResponse, ControlLibraryResponse, ControlObservationResponse, GenericResponse } from '../../types';
import customFetchBase from 'src/store/customFetchBase';
import { NewControlObservationInput } from 'src/views/pages/setup/controls/observation';

export const observationApi = createApi({
  reducerPath: 'observationApi',
  baseQuery: customFetchBase,
  tagTypes: ['auditControlLiabraryResponseDTO'],
  endpoints: (builder) => ({
    newControlObservation: builder.mutation<ControlObservationResponse, NewControlObservationInput>(
      {
        query(data) {
          return {
            url: '/library/create',
            method: 'POST',
            body: data,
          };
        },
        invalidatesTags: ['auditControlLiabraryResponseDTO'],
      },
    ),
    updateControlObservation: builder.mutation<
      ControlObservationResponse,
      NewControlObservationInput
    >({
      query(data) {
        return {
          url: `/library/update/${data.id}`,
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ['auditControlLiabraryResponseDTO'],
    }),
    getAllControlObservations: builder.query<ControlObservationResponse[], void>({
      query() {
        return {
          url: '/library/getall',
          credentials: 'include',
        };
      },
      providesTags: ['auditControlLiabraryResponseDTO'],
      transformResponse: (data: {
        customAuditControlLiabraryResponseDTO: ControlObservationResponse[];
      }) => data.customAuditControlLiabraryResponseDTO,
    }),
    getAllControlObservationsByCategory: builder.query<ControlObservationResponse[], number>({
      query(category: number) {
        return {
          url: `/library/getbycat/${category}`,
          credentials: 'include',
        };
      },
      providesTags: ['auditControlLiabraryResponseDTO'],
      transformResponse: (data: {
        customAuditControlLiabraryResponseDTO: ControlObservationResponse[];
      }) => data.customAuditControlLiabraryResponseDTO,
    }),
    getAllControlRiskWeights: builder.query<GenericResponse[], void>({
      query() {
        return {
          url: '/library/getRiskWeights',
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditControlRiskWeightResponseDTO: GenericResponse[] }) =>
        data.auditControlRiskWeightResponseDTO,
    }),
    getAllControlRiskTypes: builder.query<GenericResponse[], void>({
      query() {
        return {
          url: '/library/getRiskTypes',
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditControlRiskTypeResponseDTO: GenericResponse[] }) =>
        data.auditControlRiskTypeResponseDTO,
    }),
    getAllControls: builder.query<ControlLibraryResponse[], void>({
      query() {
        return {
          url: '/library/getControls',
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditControlResponseDTO: ControlLibraryResponse[] }) =>
        data.auditControlResponseDTO,
    }),
    getAllControlRisks: builder.query<GenericResponse[], void>({
      query() {
        return {
          url: '/library/getRisks',
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditControlRiskResponseDTO: GenericResponse[] }) =>
        data.auditControlRiskResponseDTO,
    }),
    getAllSampleBasis: builder.query<GenericResponse[], void>({
      query() {
        return {
          url: '/library/getSampleBases',
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditSampleBaseResponseDTO: GenericResponse[] }) =>
        data.auditSampleBaseResponseDTO,
    }),
    getCategoriesCounts: builder.query<ControlCountResponse[], void>({
      query() {
        return {
          url: '/library/getcounts',
          credentials: 'include',
        };
      },
      transformResponse: (data: { libraryCountResponseDTO: ControlCountResponse[] }) =>
        data.libraryCountResponseDTO,
    })
  }),
});

export const {
  useNewControlObservationMutation,
  useUpdateControlObservationMutation,
  useGetAllControlObservationsQuery,
  useGetAllControlObservationsByCategoryQuery,
  useLazyGetAllControlObservationsByCategoryQuery,
  useGetAllControlsQuery,
  useGetAllControlRiskWeightsQuery,
  useGetAllControlRisksQuery,
  useGetAllControlRiskTypesQuery,
  useGetAllSampleBasisQuery,
  useGetCategoriesCountsQuery
} = observationApi;
