import axios from '../../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import { ListTopCardsType, PagingResponse } from 'src/store/types';

const API_URL = '/api/data/list/ListData';

interface StateType {
  lists: any[];
  records: {};
  categories: any[];
  currentFilter: ListTopCardsType;
  listSearch: string;
  currentList: any;
  paging: PagingResponse;
}

const initialState = {
  lists: [],
  records: {},
  categories: [],
  currentFilter: {
    key: '',
    title: '',
    value: '',
    bgcolor: '',
    color: '',
    href: '',
    icon: '',
    digits: '',
  },
  listSearch: '',
  currentList: {},
  paging: {
    totalCount: 0,
    pageNumber: 0,
    pageSize: 0,
    totalPages: 0,
    sortDirection: '',
    orderBy: '',
  },
};

export const ListSlice = createSlice({
  name: 'list',
  initialState,
  reducers: {
    getLists: (state, action) => {
      state.lists = action.payload;
    },
    getRecords: (state, action) => {
      // state.records = {a1: action.payload.data as any[]};
      (state.records as any)[action.payload.key] = action.payload.data as any[];

      state.paging = action.payload?.paging;
    },
    getCategories: (state, action) => {
      state.categories = action.payload;
    },
    setVisibilityFilter: (state, action) => {
      state.currentFilter = action.payload;
    },
    SearchList: (state, action) => {
      state.listSearch = action.payload;
    },
    SelectList: (state, action) => {
      state.currentList = action.payload;
    },
    ClearList: (state: StateType) => {
      state = initialState;
    },
    // DeleteList: (state: StateType, action) => {
    //   const index = state.lists.findIndex((list) => list.Id === action.payload);
    //   state.lists.splice(index, 1);
    // },
  },
});

export const {
  getLists,
  getRecords,
  getCategories,
  setVisibilityFilter,
  SearchList,
  SelectList,
  ClearList,
} = ListSlice.actions;

export const fetchLists = (data: any[]) => async (dispatch: AppDispatch) => {
  try {
    // const response = await axios.get(`${API_URL}`);
    dispatch(getLists(data));
  } catch (err: any) {
    throw new Error(err);
  }
};

export const fetchRecords =
  ({ key, data, paging }: { key: string; data: any[]; paging?: PagingResponse }) =>
  async (dispatch: AppDispatch) => {
    try {
      // const response = await axios.get(`${API_URL}`);
      dispatch(getRecords({ key, data, paging }));
    } catch (err: any) {
      throw new Error(err);
    }
  };

export const fetchCategories = (data: any[]) => async (dispatch: AppDispatch) => {
  try {
    // const response = await axios.get(`${API_URL}`);
    dispatch(getCategories(data));
  } catch (err: any) {
    throw new Error(err);
  }
};

export default ListSlice.reducer;
