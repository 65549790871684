import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'src/store/Store';
import { format } from 'date-fns';
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  IconButton,
  Chip,
  Stack,
  Avatar,
  Tooltip,
  TextField,
  Pagination,
  useTheme,
  TableContainer,
  styled,
  TableFooter,
  TablePagination,
  Button,
  Toolbar,
  InputAdornment,
} from '@mui/material';
import { fetchLists, SearchList, SelectList } from 'src/store/apps/lists/ListSlice';
import { IconEditCircle, IconFilter, IconSearch, IconTrash } from '@tabler/icons';
// import { ListType } from 'src/types/apps/list';
import { ColumnDef, ListTopCardsType } from 'src/store/types';
import { ListTableTemplateProps } from '.';
import * as util from 'src/utils/common';
import AppSkeleton from '../skeleton';
import BlankCard from 'src/components/shared/BlankCard';
import TablePaginationActions from '../pagination';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router';
import CustomCheckbox from 'src/components/forms/theme-elements/CustomCheckbox';

export const StyledTable = styled(Table)(
  ({ theme }) => `
  & tbody tr:nth-of-type(odd) {
    background-color: white;
  }

  & tbody tr:nth-of-type(even) {
    background-color: ${theme.palette.primary.light};
  }
  
  & thead th {
    background-color: ${theme.palette.primary.main}; 
    color: ${theme.palette.primary.contrastText}; 
  }

  `,
);

interface EnhancedTableToolbarProps {
  numSelected: number;
  handleSearch: ChangeEvent<HTMLInputElement> | any;
  search: string;
}

type ListTableProps = {
  dataIsLoading: boolean;
  columns: ColumnDef[];
  navigateTo?: string;
  topButtonAction?: () => void;
  topButtonTitle?: string;
  showEditAction?: boolean;
  showDeleteAction?: boolean;
  allowRowSelection?: boolean;
  selectedRows?: (rows: any[]) => void;
  titleField?: string;
  descField?: string[];
  titleFieldWidth?: number;
  marginTop?: number;
  noDataFoundMessage?: string;
  checkboxfield?: string;
  disablecheckboxfield?: (row: any) => boolean;
  datasource?: any[];
  listName: string;
  searchField?: string;
  setPage?: (page: number) => void;
};

const ListTable = ({
  columns,
  dataIsLoading,
  navigateTo,
  topButtonAction,
  topButtonTitle,
  showEditAction,
  showDeleteAction,
  allowRowSelection,
  selectedRows,
  titleField,
  descField,
  titleFieldWidth,
  marginTop,
  noDataFoundMessage,
  checkboxfield,
  disablecheckboxfield,
  datasource,
  listName,
  searchField,
  setPage,
}: ListTableProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation(); // Get the current URL location

  const [localPage, setLocalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState<any[]>([]);
  const [search, setSearch] = useState<string>('');
  const [filteredCols, setFilteredCols] = useState<ColumnDef[]>();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');

  const getValue = (row: any, col: any) => {
    let retvalue = '';
    if (col) {
      if (col.valueGetter) {
        retvalue = col.valueGetter(row);
      } else {
        retvalue = row[col.fieldName];
      }
      if (col.render) {
        if (col.valueFormatter) {
          retvalue = col.render(col.valueFormatter(retvalue));
        } else {
          retvalue = col.render({ row: row, value: retvalue });
        }
      } else {
        if (col.valueFormatter) {
          retvalue = col.valueFormatter(retvalue);
        }
      }
    }

    return retvalue;
  };

  const getValueByColName = (row: any, colname: string) => {
    const col = columns?.find((it) => it.fieldName === colname);

    if (col) {
      return getValue(row, col);
    } else {
      if (row[colname]) {
        return row[colname];
      }
    }
  };

  const { records, paging } = useSelector(
    (state) => state.listReducer, // ((state.listReducer.records && (state.listReducer.records as any)) || [])[listName],
  );

  const lists = ((records as any) || [])[listName];

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleChangePage = (event: any, newPage: any) => {
    if (paging) {
      if (setPage) {
        setPage(newPage + 1);
      }
    } else {
      setLocalPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    if (paging) {
      if (setPage) {
        setPage(1);
      }
    } else {
      setLocalPage(1);
    }
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(lists);

      return;
    }
    setSelected([]);
  };

  const isSelected = (id: string) => selected?.find((row) => String(row.id) === String(id));

  const handleClick = (event: any, id: string) => {
    const selectedIndex = selected?.findIndex((row) => String(row.id) === String(id));
    const currentSelected = lists?.find((row: any) => String(row.id) === String(id));

    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, { ...currentSelected }];
      newSelected[selected.length][checkboxfield || 'selected'] = true;
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);

    selectedRows && selectedRows(newSelected);
  };

  const pagedatasource = (datasource || lists || [])?.map((ds: any, index: number) => {
    return { rowid: index + 1, ...ds };
  });

  useEffect(() => {
    setSelected(
      pagedatasource?.filter((pg: any) => Boolean(pg[checkboxfield || 'selected'] || false)),
    );

    setFilteredCols(columns.filter((col) => !col.hide));
  }, [dataIsLoading, lists]);

  // Effect hook to reset search whenever the URL changes
  useEffect(() => {
    setSearch(''); // Reset the search field when the URL changes
  }, [location]); // Dependency on location to trigger effect on URL change

  return (
    <Box mt={marginTop || 1}>
      <Stack direction="row">
        <Box>
          {topButtonTitle && (
            <Button
              type="button"
              variant="outlined"
              disableElevation
              color="primary"
              size="large"
              onClick={() => {
                if (topButtonAction) {
                  topButtonAction();
                } else {
                  navigate(navigateTo + '/new');
                }
              }}
            >
              {topButtonTitle}
            </Button>
          )}
        </Box>
        <Box sx={{ width: '300px', ml: 'auto' }} mb={2}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconSearch size="1.1rem" />
                </InputAdornment>
              ),
            }}
            placeholder="Search ..."
            size="medium"
            value={search}
            onChange={handleSearch}
          />
        </Box>
      </Stack>
      <BlankCard>
        <TableContainer>
          <StyledTable
            aria-label="custom Report"
            sx={{
              whiteSpace: {
                xs: 'wrap',
                sm: 'unset',
              },
            }}
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {allowRowSelection && (
                  <TableCell padding="checkbox">
                    {/* <CustomCheckbox
                      color="primary"
                      checked={
                        pagedatasource?.length > 0 && selected.length === pagedatasource?.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{
                        'aria-label': 'select all desserts',
                      }}
                    /> */}
                  </TableCell>
                )}

                <TableCell style={{ width: 10 }}>
                  <Typography variant="h6" fontWeight="bold">
                    #
                  </Typography>
                </TableCell>
                {filteredCols?.map((col: ColumnDef, index: number) => (
                  <TableCell
                    key={index.toString()}
                    align={col.align === 'center' ? 'center' : 'left'}
                    // style={{ width: `${col.width}` }}
                  >
                    <Typography variant="h6" fontWeight="bold">
                      {util.toPascalCaseWithSpaces(col.title)}
                    </Typography>
                  </TableCell>
                ))}
                {showEditAction || showDeleteAction ? (
                  <TableCell style={{ width: 80 }}>
                    <Typography variant="h6" fontWeight="bold">
                      Action
                    </Typography>
                  </TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataIsLoading ? (
                <TableRow key="loader">
                  <TableCell colSpan={columns.length + 2} align="center">
                    <AppSkeleton />
                  </TableCell>
                </TableRow>
              ) : !pagedatasource || pagedatasource?.length === 0 ? (
                <TableRow key="nodatarow">
                  <TableCell colSpan={columns.length + 2} align="center">
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {noDataFoundMessage || 'No data found'}
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                (rowsPerPage > 0
                  ? paging === undefined
                    ? pagedatasource
                        .filter(
                          (pd: any) =>
                            search?.length === 0 ||
                            pd[searchField || 'title']
                              ?.toLowerCase()
                              .includes(search.toLowerCase()),
                        )
                        .slice(localPage * rowsPerPage, localPage * rowsPerPage + rowsPerPage)
                    : pagedatasource.filter(
                        (pd: any) =>
                          search?.length === 0 ||
                          pd[searchField || 'title']?.toLowerCase().includes(search.toLowerCase()),
                      )
                  : pagedatasource
                )?.map((row: any, index: number) => {
                  // const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const selectedrow = selected?.find((it) => it.id === row.id) || {};

                  return (
                    <TableRow
                      key={index.toString()}
                      hover
                      // onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      // aria-checked={isItemSelected}
                      tabIndex={-1}
                    >
                      {allowRowSelection && (
                        <TableCell padding="checkbox">
                          <CustomCheckbox
                            // defaultChecked={Boolean(row[checkboxfield || ''] || false)}
                            // color="primary"
                            checked={selectedrow[checkboxfield || 'selected'] || false}
                            disabled={(disablecheckboxfield && disablecheckboxfield(row)) || false}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                            onClick={(event) => handleClick(event, row.id)}
                          />
                        </TableCell>
                      )}

                      <TableCell>{row['rowid'].toString()}</TableCell>
                      {filteredCols?.map((col, index) => (
                        <TableCell
                          key={index.toString()}
                          align={col.align === 'center' ? 'center' : 'left'}
                        >
                          {col.multicolumn ? (
                            <Box>
                              <Typography
                                variant="subtitle1"
                                sx={{ maxWidth: `${titleFieldWidth || '300'}px` }}
                                fontWeight={600}
                                // noWrap
                              >
                                {getValueByColName(row, titleField || 'title')}
                              </Typography>
                              <Stack direction="column" spacing={2}>
                                {descField &&
                                  descField?.map((desc, index) => (
                                    <Typography
                                      key={index.toString()}
                                      color="textSecondary"
                                      sx={{ maxWidth: `${titleFieldWidth || '300'}px` }}
                                      variant="subtitle2"
                                      fontWeight={400}
                                    >
                                      {getValueByColName(row, desc || 'description')}
                                    </Typography>
                                  ))}
                              </Stack>
                            </Box>
                          ) : (
                            <Typography variant="subtitle1" noWrap={col.noWrap === true}>
                              {getValue(row, col)}
                            </Typography>
                          )}
                        </TableCell>
                      ))}

                      {(showEditAction || showDeleteAction) && (
                        <TableCell align="right">
                          <Box sx={{ display: 'flex' }}>
                            {/* Show Edit Action if enabled */}
                            {showEditAction && (
                              <Tooltip title="Edit">
                                <IconButton
                                  onClick={() => {
                                    dispatch(SelectList(row));
                                    navigate(navigateTo + `/${row.id}` || '#');
                                  }}
                                >
                                  <IconEditCircle size="22" />
                                </IconButton>
                              </Tooltip>
                            )}

                            {/* Show Delete Action if enabled */}
                            {showDeleteAction && (
                              <Tooltip title="Delete">
                                <IconButton
                                  onClick={() => {
                                    dispatch(SelectList(row));
                                    navigate(navigateTo + `/${row.id}` || '#');
                                  }}
                                >
                                  <IconTrash size="22" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Box>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[{ label: 'All', value: -1 }, 5, 10, 25]}
                  colSpan={columns.length + 1}
                  count={paging?.totalCount > 0 ? paging?.totalCount : pagedatasource?.length}
                  rowsPerPage={rowsPerPage}
                  page={paging?.pageNumber > 0 ? paging?.pageNumber - 1 : localPage}
                  SelectProps={{
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  sx={{
                    '& .MuiTablePagination-displayedRows': {
                      marginBottom: 0, // Set margin bottom to 0 for displayed rows
                    },
                    '& .MuiTablePagination-toolbar': {
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: 0, // Ensures toolbar also has margin bottom 0
                    },
                    '& .MuiTypography-root': {
                      marginBottom: 0, // Apply margin bottom 0 to all Typography elements within the TablePagination
                    },
                    '& .MuiTablePagination-selectLabel': {
                      marginBottom: 0, // Set margin bottom to 0 for "Rows per page" label
                    },
                  }}
                />
              </TableRow>
            </TableFooter>
          </StyledTable>
        </TableContainer>
      </BlankCard>
    </Box>
  );
};

export default ListTable;
