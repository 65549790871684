import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  ControlCategoryResponse,
  ControlLibraryResponse,
  ControlSourceResponse,
  GenericResponse,
  PagingResponse,
} from 'src/store/types';
import customFetchBase from 'src/store/customFetchBase';
import { NewControlLibraryInput } from 'src/views/pages/setup/controls/library';
import { NewWorkProgramTemplateInput } from 'src/views/pages/setup/controls/workprograms';

export const controlApi = createApi({
  reducerPath: 'controlApi',
  baseQuery: customFetchBase,
  tagTypes: ['auditControlResponseDTO'],
  endpoints: (builder) => ({
    newControlLibrary: builder.mutation<ControlLibraryResponse, NewControlLibraryInput>({
      query(data) {
        return {
          url: '/control/create',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['auditControlResponseDTO'],
    }),
    newWorkprogramTemplate: builder.mutation<{}, NewWorkProgramTemplateInput[]>({
      query(data) {
        return {
          url: '/control/template/create',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['auditControlResponseDTO'],
    }),
    updateControlLibrary: builder.mutation<ControlLibraryResponse, NewControlLibraryInput>({
      query(data) {
        return {
          url: `/control/update/${data.id}`,
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ['auditControlResponseDTO'],
    }),
    getAllControls: builder.query<ControlLibraryResponse[], void>({
      query() {
        return {
          url: '/control/getall',
          credentials: 'include',
        };
      },
      providesTags: ['auditControlResponseDTO'],
      transformResponse: (data: { auditControlResponseDTO: ControlLibraryResponse[] }) =>
        data.auditControlResponseDTO,
    }),
    getAllControlTypes: builder.query<GenericResponse[], void>({
      query() {
        return {
          url: '/control/getControlType',
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditControlTypeResponseDTO: GenericResponse[] }) =>
        data.auditControlTypeResponseDTO,
    }),
    getTemplateNames: builder.query<string[], void>({
      query() {
        return {
          url: '/control/template/list',
          credentials: 'include',
        };
      },
      transformResponse: (data: { string: string[] }) => data.string,
    }),
    getTemplateControls: builder.query<any[], string>({
      query(name: string) {
        return {
          url: `/control/template/controls/${name}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditControlResponseDTO: GenericResponse[] }) =>
        data.auditControlResponseDTO,
    }),
    getAllControlCategories: builder.query<ControlCategoryResponse[], void>({
      query() {
        return {
          url: '/control/getallCategories',
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditControlCategoryResponseDTO: ControlCategoryResponse[] }) =>
        data.auditControlCategoryResponseDTO,
    }),
    getAllControlsByCategory: builder.query<
      { data: ControlLibraryResponse[]; paging: PagingResponse },
      { id: number; page: number; pageSize: number }
    >({
      query({ id, page, pageSize }) {
        return {
          url: `/control/getbycategory/${id}?page=${page}&pageSize=${pageSize}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: {
        auditControlResponseDTO: ControlLibraryResponse[];
        paging: PagingResponse;
      }) => ({ data: data.auditControlResponseDTO, paging: data.paging }),
    }),
    getAllControlSources: builder.query<ControlSourceResponse[], void>({
      query() {
        return {
          url: '/control/getallSources',
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditControlSourceResponseDTO: ControlSourceResponse[] }) =>
        data.auditControlSourceResponseDTO,
    }),
    getAllControlRisks: builder.query<GenericResponse[], void>({
      query() {
        return {
          url: '/control/getallRisks',
          credentials: 'include',
        };
      },
      transformResponse: (data: { result: GenericResponse[] }) => data.result,
    }),
  }),
});

export const {
  useNewControlLibraryMutation,
  useNewWorkprogramTemplateMutation,
  useUpdateControlLibraryMutation,
  useGetAllControlsQuery,
  useGetAllControlCategoriesQuery,
  useLazyGetAllControlsByCategoryQuery,
  useGetAllControlRisksQuery,
  useGetAllControlSourcesQuery,
  useGetAllControlTypesQuery,
  useGetTemplateNamesQuery,
  useLazyGetTemplateControlsQuery,
} = controlApi;
