import React from 'react';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import ChildCard from 'src/components/shared/ChildCard';
import ListFilter from './ListFilter';
import ListTable from './ListTable';
import { ColumnDef, ListTopCardsType } from 'src/store/types';
import { AppState, useDispatch } from 'src/store/Store';
import { useEffect, useState } from 'react';
import { fetchRecords } from 'src/store/apps/lists/ListSlice';
import * as routes from 'src/constants/routes';
import { useSelector } from 'react-redux';

export interface ListTableTemplateProps {
  listTitle: string;
  listSubTitle: string;
  columns: ColumnDef[];
  listName: string;
  fetchLists: any;
  hideBanner?: boolean;
  maxHeight?: number;
  hideSummary?: boolean;
  topCards?: ListTopCardsType[];
  navigateTo?: string;
  topButtonAction?: () => void;
  topButtonTitle: string;
  showEditAction: boolean;
  allowRowSelection?: boolean;
  showDeleteAction?: boolean;
  lazyParams?: any;
  crumb?: any[];
  titleField?: string;
  searchField?: string;
  descField?: string[];
}

export const LazyListTableTemplate = React.memo((props: ListTableTemplateProps) => {
  const customizer = useSelector((state: AppState) => state.customizer);

  const dispatch = useDispatch();

  const [fetch, { data, error, isFetching, isSuccess }] = props.fetchLists();

  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (isSuccess) {
      dispatch(fetchRecords({ key: props.listName, data: data.data || data, paging: data.paging }));
    }
  }, [isFetching, isSuccess]);

  // useEffect(() => {
  //   fetch(props.lazyParams);
  // }, [props.lazyParams]);

  useEffect(() => {
    if (props.lazyParams) {
      let newLazyParams = props.lazyParams;

      if (props.lazyParams.page && page > 0) {
        newLazyParams = { ...newLazyParams, page: page };
        //props.lazyParams.page = page;
      }

      fetch(newLazyParams);
    }
  }, [dispatch, props.lazyParams, page]);

  let BCrumb = [
    {
      to: routes.INDEX,
      title: 'Home',
    },
  ];

  if (props.crumb) {
    BCrumb = BCrumb.concat(props.crumb);
  } else if (customizer.breadCrumbs) {
    BCrumb = BCrumb.concat(customizer.breadCrumbs);
  }

  return (
    <PageContainer title={props.listTitle} description="">
      {!props.hideBanner ? (
        <Breadcrumb title={props.listTitle!} subtitle={props.listSubTitle} items={BCrumb} />
      ) : (
        <></>
      )}
      <ChildCard>
        {!props.hideSummary || (props.topCards || []).length > 0 ? (
          <ListFilter boxes={props.topCards || []} />
        ) : (
          <></>
        )}
        <ListTable
          dataIsLoading={isFetching}
          columns={props.columns || []}
          navigateTo={props.navigateTo}
          topButtonAction={props.topButtonAction}
          topButtonTitle={props.topButtonTitle}
          showEditAction={props.showEditAction}
          showDeleteAction={props.showDeleteAction}
          allowRowSelection={props.allowRowSelection}
          marginTop={4}
          listName={props.listName}
          titleField={props.titleField}
          searchField={props.searchField}
          descField={props.descField}
          setPage={setPage}
        />
      </ChildCard>
    </PageContainer>
  );
});

export const ListTableTemplate = React.memo((props: ListTableTemplateProps) => {
  const customizer = useSelector((state: AppState) => state.customizer);
  const dispatch = useDispatch();

  const { data, error, isFetching, isSuccess, refetch: refetchlist } = props.fetchLists();

  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (isSuccess) {
      dispatch(fetchRecords({ key: props.listName, data: data, paging: data.paging }));
    }
  }, [isFetching, isSuccess]);

  useEffect(() => {
    refetchlist();
  }, []);

  useEffect(() => {
    if (page && page > 1) {
      refetchlist({ page: page, pageSize: 10 });
    }
  }, [page]);

  let BCrumb = [
    {
      to: routes.INDEX,
      title: 'Home',
    },
  ];

  if (props.crumb) {
    BCrumb = BCrumb.concat(props.crumb);
  } else if (customizer.breadCrumbs) {
    BCrumb = BCrumb.concat(customizer.breadCrumbs);
  }

  return (
    <PageContainer title={props.listTitle} description="">
      {!props.hideBanner ? (
        <Breadcrumb title={props.listTitle!} subtitle={props.listSubTitle} items={BCrumb} />
      ) : (
        <></>
      )}
      <ChildCard>
        {!props.hideSummary || (props.topCards || []).length > 0 ? (
          <ListFilter boxes={props.topCards || []} />
        ) : (
          <></>
        )}
        <ListTable
          dataIsLoading={isFetching}
          columns={props.columns || []}
          navigateTo={props.navigateTo}
          topButtonAction={props.topButtonAction}
          topButtonTitle={props.topButtonTitle}
          showEditAction={props.showEditAction}
          showDeleteAction={props.showDeleteAction}
          allowRowSelection={props.allowRowSelection}
          marginTop={4}
          listName={props.listName}
          searchField={props.searchField}
          setPage={setPage}
        />
      </ChildCard>
    </PageContainer>
  );
});
